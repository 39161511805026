@import '~@porsche-design-system/utilities/scss';

.clear_container {
  width: p-px-to-rem(50px);
  cursor: pointer;
  position: absolute;
  top: 30%;
  transform: translate(0, -50%);
  fill: #313639;
  right: 1.0rem;
  height: 1.875rem;

  .clear_container__clear-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: p-px-to-rem(18px);
    height: p-px-to-rem(18px);
    background-color: #626669;
    border-radius: 50%;
    margin-left: p-px-to-rem(8px);
    cursor: pointer;
    margin-top: 50%;
    transform: translate(0, -50%);

    svg {
      fill: #fff;
    }
  }
}

.prevent-focus{
  pointer-events: none;
}