@import '~@porsche-design-system/utilities/scss';

.validation-message{
  font-size: 13px;
  position: absolute;
}

.custom-field {
  &:not(.disabled):hover {
    .custom-datepicker__icon {
      fill: $p-color-brand;
    }
  }

  .custom-datepicker__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    fill: #626669;
    right: p-px-to-rem(20px);
    width: p-px-to-rem(16px);
    height: p-px-to-rem(16px);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: $p-color-brand;
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: $p-color-brand;
  }

  input {
    position: relative;
    height: 2.625rem;
    padding: 0.9375rem 0.75rem 0.125rem;
    line-height: 1.3;
    border-radius: 0;
    background: 0 0;
    background: #fff;
    color: #000;
    border: #c8cacb 0.0625rem solid;
    width: 100%;
    height: 2.625rem;
    display: block;
    padding: 0.5rem 0.625rem;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1rem;
    border-radius: 0;

    @media only screen and (min-width: 62.5rem) {
      height: 3.75rem;
      padding: 1.225rem 0.785rem 0.375rem;
    }

    @media only screen and (min-width: 30rem) {
      height: 3rem;
      padding: 1.225rem 0.785rem 0.375rem;
    }
  }
  
  .input-error{
    border-color: red;
  }
  
  &.disabled {
    input {
      background-color: #e5e5e5;
      opacity: 0.5;
    }
  }
}
