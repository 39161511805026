@import '~@porsche-design-system/utilities/scss';
@import '~@porsche-design-system/scss-utils/index';

.info-banner{
    position: fixed;
    left: 0;
}

.content-div {
    margin-top: p-px-to-rem(30px);
    width: p-px-to-rem(300px);
    margin-bottom: p-px-to-rem(50px);
}

.export-all-limitNr-btn {
    float: left;
}
