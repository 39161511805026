@import '~@porsche-design-system/utilities/scss';
@import '~@porsche-design-system/scss-utils/index';

.button {
  text-align: left;
}
.cancel-button{
  margin-right: p-px-to-rem(15px);
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-1 {
  flex: 1;

}

.flex-3 {
  flex: 3;
  margin-right: p-px-to-rem(30px);
}

.error-input {
  border-color: red;
}


.wrapper {
  padding-top: 0;
  padding-bottom: p-px-to-rem(50px);
  width: 100%;
  margin: auto;
  text-align: center;

  .create-model__banner_position {
    position: fixed;
    left: 0;
  }

  .divider{
    margin-bottom: p-px-to-rem(30px);
  }

  .delete-confirm-banner{
    width: 50%;
    left: 25%;
    &__description{
      float: right;
      .confirm-button:first-child{
        margin-right: p-px-to-rem(15px);
      }
    }
  }

  .p-grid-item {
    padding-bottom: p-px-to-rem(30px);
  }

  .create-model-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: p-px-to-rem(30px);
    border-bottom: 2px solid #d3d5d6;

    .create-model-header__title {
      font-size: p-px-to-rem(36px);
      font-weight: 100;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #000000;
    }

    .create-model-header__subtitle {
      font-size: p-px-to-rem(16px);
      line-height: 1.5;
      color: #191f22;
      margin: p-px-to-rem(10px) 0;
      font-weight: bold;
    }
  }


  .create-model-header__display-section {
    display: flex;
    flex-direction: row;

    .create-model-header-section__divider {
      margin: 0 p-px-to-rem(30px);
      width: p-px-to-rem(1px);
      background-color: #d3d5d6;
      align-self: stretch;
    }
  }


  .create-model__input_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;

    .create-model__input_container__subsection {
      flex: 3;
    }

    .create-model__input_container__spacer {
      flex: 1;
    }

    .create-model__input_section__field {
      margin-top: p-px-to-rem(20px);
    }

    .submit-button {
      text-align: right;

      .button {
        margin-top: p-px-to-rem(30px);
      }
    }
  }

  .create-model-details__input_section__field {
    margin-top: p-px-to-rem(20px);

    &.create-model-details__input_section__field--compound {
      display: flex;
    }
  }

  .create-model-details_checkbox {
    margin-top: p-px-to-rem(33px);
  }

  .create-model-details_addMore {
    width: p-px-to-rem(150px)
  }
  .add-sos-grid-item{
    width: 0 !important;
  }

  .delete-sos-icon-grid-item {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .delete-sos-icon {
    margin-top: p-px-to-rem(35px);
  }

  .market-dependent-sos-grid-item {
    padding-bottom: 0 !important;
  }

  .create-model-details__input_section__field--compound-left {
    flex: 2;
    margin-right: p-px-to-rem(20px);
  }

  .create-model-details__input_section__field--compound-right {
    flex: 1;
  }

  .create-model-details__input_container__subsection {
    flex: 3;
  }

  .open-actions-save-button {
    min-height: p-px-to-rem(40px);

    //   @media (max-width: p-px-to-rem(variables.$breakpoint-tablet)) {
    //     min-width: p-px-to-rem(20px);
    //   }

    //   @media (max-width: p-px-to-rem(variables.$breakpoint-mobile)) {
    //     min-width: p-px-to-rem(20px);
    //   }
  }

  .timepicker-box {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    border: #c8cacb 0.0625rem solid;
    border-radius: 0px;
    font-size: p-px-to-rem(16px);
    font-family: $p-font-primary;
  }

  .timepicker-box:disabled{
    background-color: #e5e5e5;
    opacity: 0.5;
    border-color: rgb(50, 54, 57);
  }

  .error-input {
    border-color: red;
  }
}

.quotatab-year-change {
  margin-top: p-px-to-rem(5px);
}

.quotatab-year {
  margin-left: p-px-to-rem(50px);
  margin-right: p-px-to-rem(50px);
}

.model-select-wrapper {
  margin-top: p-px-to-rem(50px);
  margin-bottom: p-px-to-rem(30px);
}

.headline {
  margin-bottom: p-px-to-rem(20px);
  display: flex;
  flex-direction: column;
}

.headline-modelluebersicht {
  @extend .headline;
  margin-top: p-px-to-rem(50px);
}

