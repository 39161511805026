@import '~@porsche-design-system/utilities/scss';

.custom_table {
	border-collapse: collapse;
	border-spacing: 0;
	padding: p-px-to-rem(35px);
	width: 100%;
	text-align: left;

	th,
	td {
		padding: p-px-to-rem(20px);
	}

	tr:not(:last-child) {
		border-bottom: 1px solid #f2f2f2;
	}

	th {
		border-bottom: 1px solid #f2f2f2;
		cursor: pointer;
	}
}
