@import "~@porsche-design-system/utilities/scss";
@import "~@porsche-design-system/scss-utils/index";


.create-model__banner_position {
	padding-top: 0;
	padding-bottom: p-px-to-rem(50px);
	width: 100%;
	margin: auto;
	text-align: center;
	position: fixed;
	left: 0;
}

.select-box {
	width: 30%;
}

.headline {
	margin-top: p-px-to-rem(30px);
}

.limitation-number {
	width: p-px-to-rem(100px);
	margin-top: p-px-to-rem(10px);
	margin-bottom: p-px-to-rem(10px);
	display: flex;
}

.limitation-number-checkbox {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: static;
	height: 1.5rem;
	width: 1.5rem;
	flex-shrink: 0;
	display: block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-size: 1.5rem;
	background-position: -1px -1px;
	background-color: #fff;
	transition: border 0.24s ease, background-color 0.24s ease;
	opacity: 1;
	border: #626669 solid 1px;
	border-radius: 0;
	outline: transparent solid 1px;
	outline-offset: 2px;
	cursor: pointer;
}
.limitation-number-checkbox:checked {
	border-color: #323639;
	background-color: #323639;
	background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23fff" d="M9 19l-6-7h1.5l4.49 5.36L19.5 5H21L9 19z"/></svg>');
}

.limitation-number-checkbox:disabled {
	background-color: rgb(150, 152, 154) !important;
	cursor: not-allowed !important;
	border-color: rgb(150, 152, 154) !important;
}

.limitation-number-label {
	width: auto;
	padding: 0px 0px 0px 0.5rem;
	position: static;
	height: auto;
	margin: 0px;
	white-space: normal;
	overflow: visible;
	clip: auto;
	clip-path: none;
	order: 1;
	display: inline-block;
	cursor: pointer;
	color: #000;
	transition: color var(--p-animation-hover-duration, 0.24s) ease;
}

.timer {
	margin-right: p-px-to-rem(20px);
	margin-top: p-px-to-rem(10px);
}

.quotesText {
	margin-top: p-px-to-rem(20px);

	&.warning {
		margin-left: $p-spacing-16;
	}
}

.legend {
	margin-top: p-px-to-rem(20px);
}

.refresh-btn {
	border-color: #c8cacb !important;
}
.flex-limitation-numbers {
	margin-top: p-px-to-rem(40px);
	margin-bottom: p-px-to-rem(40px);
}

.inner-content {
	margin-top: p-px-to-rem(30px);
}

.delete-confirm-banner__description {
	float: unset !important;
}

.my-numbers-table {
	margin-top: p-px-to-rem(20px);
	margin-bottom: p-px-to-rem(15px);
}

.importer-transfer-input {
	margin-top: p-px-to-rem(20px);
}

.importer-transfer-text {
	margin-top: p-px-to-rem(32px);
	display: inline-block;
}

.submit-button {
	text-align: right;
	.button {
		margin-top: p-px-to-rem(30px);
	}
}

.export-button {
	float: right;
	margin-top: p-px-to-rem(20px);
}

.search-limitation-number-flex {
	margin-top: p-px-to-rem(20px);
	margin-bottom: p-px-to-rem(10px);

	&.only-radio {
		margin-top: p-px-to-rem(32px);
	}
}

.search-radio {
	margin-top:  p-px-to-rem(12px);
	margin-right:  p-px-to-rem(10px);
}