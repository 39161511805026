@import '~@porsche-design-system/utilities/scss';
@import '~@porsche-design-system/scss-utils/index';


.app-main {
  margin-top: 1rem;
  .AppMain-divider {
    width: 75%;
  }
  .searchbox-space{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app-header {
  text-align: center !important;
  justify-content: center;
}

.divider {
  display: block;
  margin-top: -16px;
  width: 100%;
  height: 1px;
}