@import '~@porsche-design-system/utilities/scss';

.custom-dropdown-wrapper {
  position: relative;

  .custom-field {
    color: #000;

    .custom-dropdown__search-input {
      position: absolute;
      top: -1px;
      left: -1px;
      border: none;
      white-space: nowrap;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .custom_dropdown__icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      fill: #313639;
      right: p-px-to-rem(10px);
      width: p-px-to-rem(30px);
      height: p-px-to-rem(30px);
      pointer-events: none;

      &.dark {
        fill: #ffffff;
      }
    }

    .custom-dropdown-input {
      position: relative;
      height: 2.625rem;
      padding: 0.9375rem 0.75rem 0.125rem;
      line-height: 1.3;
      cursor: pointer;
      background: #fff;
      border: #c8cacb 0.0625rem solid;
      width: 100%;
      height: 3rem;
      padding-right: p-px-to-rem(50px);
      display: block;
      outline: 0;
      box-sizing: border-box;
      font-size: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.dark {
        background-color: #313639;
        color: #ffffff;
        border: none;
      }

      &.disabled {
        background-color: #e5e5e5;
        opacity: 0.5;
      }

      &:hover {
        color: #d5001c;

        svg {
          fill: #d5001c;
        }
      }
    }

    .custom-dropdown__search-input--hasPlaceholder {
      padding: 1.225rem 0.785rem 0.375rem;
      padding-right: p-px-to-rem(50px);
    }

    &:hover {
      .custom-field__custom-label--empty {
        color: #d5001c !important;
      }
    }
  }

  .custom-dropdown-option-container {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    border: #c8cacb 1px solid;
    max-height: p-px-to-rem(200px);
    overflow-y: auto;
    border-top: 0;
    top: 32px;
    padding: 0;

    .custom-dropdown-option-container__option {
      align-items: center;
      list-style-type: none;
      /* word-break: break-word; */
      padding: 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
      text-align: left;
      padding: p-px-to-rem(12px);

      &:hover {
        color: #d5001c;
        cursor: pointer;
      }

      &.custom-dropdown-option-container__option--selected {
        color: #d5001c;
      }
    }
  }
}