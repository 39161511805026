@import '~@porsche-design-system/utilities/scss';
@import '~@porsche-design-system/scss-utils/index';

.App {
  font-family: $p-font-primary;

  .AppHeader {
    margin-top: $p-spacing-16;
    margin-bottom: $p-spacing-16;
    font-family: $p-font-primary;
  }

  .AppNavItem {
    padding-top: p-px-to-rem(13px);
    padding-bottom: p-px-to-rem(13px);
    font-size: $p-font-size-16;
    font-family: $p-font-primary;
  }
}

.model-select {
  width: p-px-to-rem(410px);
}

.tab-bar {
  position:absolute;
  width: fit-content;
  margin: 1rem -10% auto;
  left:50%;
  transform: translate(-50%, -50%);
  padding-top: p-px-to-rem(10px);
}

.tab-panel {
  margin-top: p-px-to-rem(30px);
}
.spinner {
  margin: 0 -50% 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}