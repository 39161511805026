@import '~@porsche-design-system/utilities/scss';
@import '~@porsche-design-system/scss-utils/index';

.upload-grid {
    margin-right: p-px-to-rem(10px) !important;
}

.headline {
    margin-top: p-px-to-rem(30px);
}

.inputFileUpload {
    margin-top: p-px-to-rem(30px);
    color: transparent;
    display: flow-root;
}

.inputFileUpload::-webkit-file-upload-button {
    visibility: hidden;
}

.inputFileUpload::before {
    content: 'Upload File';
    outline: transparent solid 1px;
    outline-offset: 2px;
    display: block;
    width:  p-px-to-rem(110px);
    min-width: 3rem;
    min-height: 3rem;
    padding-top: p-px-to-rem(10px);
    position: relative;
    margin: 0px;
    box-sizing: border-box;
    -webkit-writing-mode: horizontal-tb !important;
    border: 1px solid rgb(50, 54, 57);
    color: #ffffff;
    background-color: rgb(50, 54, 57);
    background-repeat: no-repeat;
    text-indent: p-px-to-rem(20px);
    text-shadow: none;
    cursor: pointer;
    font-family: "Porsche Next", "Arial Narrow", Arial, sans-serif;
    font-size: 1rem;
}

.inputFileUpload:hover::before {
    background-color: black;
}

.inputFileUpload:active::before {
    background-color: black;
}

.submit-button {
    justify-content: flex-end;
}

.message-field-wrapper {
    margin-top: p-px-to-rem(50px);
}

.message-field {
    margin-top: p-px-to-rem(30px);
}

.info-banner{
    position: fixed;
    left: 0;
}

.submit-file-upload-confirm-banner{
    width: 50%;
    position: fixed;
    left: 25%;
    &__description{
        .confirm-button:first-child{
            margin-right: p-px-to-rem(15px);
        }
    }
}

.message-text {
    margin-bottom: p-px-to-rem(5px);
}