@import '~@porsche-design-system/utilities/scss';

.custom_label {
  font-size: p-px-to-rem(12px);
  line-height: 2;
  color: #959899;
  position: absolute;

  &.custom_label--top-left {
    top: p-px-to-rem(-22px);
    left: 0;
  }

  &.custom_label--top-right {
    top: p-px-to-rem(-22px);
    right: 0;
  }

  &.custom_label--bottom-left {
    bottom: p-px-to-rem(-22px);
    left: 0;
  }

  &.custom_label--bottom-right {
    bottom: p-px-to-rem(-22px);
    right: 0;
  }
}
