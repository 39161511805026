@import '~@porsche-design-system/utilities/scss';

.custom-field {
  position: relative;
  display: flex;
  text-align: left;

  &.disabled {
    *:hover {
      cursor: default;
    }
  }

  &.field-overflow {
    .custom-field__wrapper {
      overflow: hidden;
    }
  }

  .custom-field__wrapper {
    flex: 1;
    position: relative;
    width: 100%;
  }

  .custom-field__custom-label {
    pointer-events: none;
    color: #959899;
    position: absolute;
    -webkit-transition: top 350ms ease, font-size 400ms ease;
    -o-transition: top 350ms ease, font-size 400ms ease;
    transition: top 350ms ease, font-size 400ms ease;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 0.85rem;
    right: 0.85rem;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 0.6875rem;
    top: 25%;

    &.custom-field__custom-label--empty {
      top: 50%;
      transform: translate(0, -50%);
      font-size: 1rem;
      color: #000;
    }
  }

  .custom-field__custom-label--multiline {
    pointer-events: none;
    color: #959899;
    background-color: #ffffff;
    position: absolute;
    -webkit-transition: top 350ms ease, font-size 400ms ease;
    -o-transition: top 350ms ease, font-size 400ms ease;
    transition: top 350ms ease, font-size 400ms ease;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 1px;
    right: 1px;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 0.6875rem;
    top: 15px;
    padding: 0.525rem 0.825rem;

    &.custom-field__custom-label--empty {
      top: 50%;
      transform: translate(0, -50%);
      font-size: 1rem;
      color: #000;
    }
  }

  &.disabled {
    .custom-field__custom-label {
      opacity: 50%;
    }
  }

  .custom_field__edit_icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: p-px-to-rem(20px);
    fill: #fff;
    width: p-px-to-rem(16px);
    height: p-px-to-rem(16px);
    cursor: pointer;
  }
}
