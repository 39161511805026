@import '~@porsche-design-system/utilities/scss';

.validation-message{
  font-size: 13px;
  position: absolute;
}

.custom-field {
  .text-field-input {
    background: #ffffff;
    color: #000000;
    border: #c8cacb 0.0625rem solid;
    width: 100%;
    height: 2.625rem;
    display: block;
    padding: 0.7rem 0.75rem 0.5rem 0.75rem;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1rem;
    position: relative;
    border-radius: 0;
  }

  .error-input{
    border-color: red;
  }
  

  .text-field-input--multiline {
    background: #ffffff;
    color: #000000;
    border: #c8cacb 0.0625rem solid;
    width: 100%;
    display: block;
    padding: 1.625rem 0.75rem 0.5rem 0.75rem;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1rem;
    position: relative;
    border-radius: 0;
  }

  @media only screen and (min-width: 30rem) {
    .text-field-input {
      height: 3rem;
      padding: 1.4rem 0.75rem 0.625rem 0.75rem;
    }
  }

  &.disabled {
    input {
      background-color: #e5e5e5;
      opacity: 0.5;
    }
  }
}
