@import '~@porsche-design-system/utilities/scss';

.tooltip_container {
  width: p-px-to-rem(38px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .tooltip_container__tooltip_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: p-px-to-rem(22px);
    height: p-px-to-rem(22px);
    background-color: #626669;
    border-radius: 50%;
    margin-top: 50%;
    transform: translate(0, -50%);
    font-size: p-px-to-rem(13px);
    font-weight: bold;
    line-height: 2.77;
    color: #ffffff;
  }

  .tooltip_container__tooltip {
    display: none;
    border: 0.0625rem solid #c8cacb;
    color: #000;
    background: #fff;
    position: absolute;
    padding: 0.4375rem 0.625rem;
    text-align: left;
    line-height: 1.3;
    font-size: 0.875rem;
    font-weight: 200;
    z-index: 100;
    white-space: nowrap;
    margin-top: p-px-to-rem(-35px);
    margin-right: auto;
    margin-left: auto;

    &.visible {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.375rem;
      left: calc(50% - 0.3125rem);
      width: 0.625rem;
      height: 0.625rem;
      background: #fff;
      border-right: 0.0625rem solid #c8cacb;
      border-bottom: 0.0625rem solid #c8cacb;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
